import axios from 'axios'
// import apiConfig from '@/config/apiConfig'
import errorHandle from '@/utils/errorHandle'
import store from '@/store'
import { ElNotification } from 'element-plus'

// const env = process.env.NODE_ENV

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: '', // apiConfig[env].BASE_URL,
  // 请求超时时间
  timeout: 10000,
  // 请求的数据格式
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

request.interceptors.request.use(
  config => {
    const token = store.state.user.token
    if (token) {
      config.headers['Access-Token'] = token
    }
    return config
  },
  error => {
    // 对错误统一处理
    errorHandle(error)
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => {
    if (response.status === 200) {
      const { data } = response
      console.log('%c [ data ]-40', 'font-size:13px; background:pink; color:#bf2c9f;', data)
      // 一期
      if (data.code === '401') {
        store.dispatch('logout')
        ElNotification.error({
          title: '错误',
          message: '账号过期，请重新登录！'
        })
      }

      // 二期
      if (data.status === 103) {
        store.dispatch('logout')
        ElNotification.error({
          title: '错误',
          message: '账号过期，请重新登录！'
        })
      }
      return response.data
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    // 对错误统一处理
    errorHandle(error)
    return Promise.reject(error)
  }
)

export default request
