// import HomeLayout from '@/layout/HomeLayout'
import BaseLayout from '@/layout/BaseLayout'
import BlankLayout from '@/layout/BlankLayout'
import Home from '@/views/home'
import AutoLogin from '@/views/user/autoLogin'

export const asyncRouterMap = [
  {
    path: '/',
    name: 'Home',
    component: BaseLayout,
    redirect: '/home',
    children: [
      // {
      //   path: '/tool',
      //   name: 'Tool',
      //   meta: { title: '绘制工具' },
      //   component: () => import(/* webpackChunkName: "BaseInfo" */ '@/views/tool')
      // },
      {
        path: '/home',
        name: 'Home',
        meta: { title: '首页' },
        component: Home
      },
      // {
      //   path: '/baseInfo',
      //   name: 'BaseInfo',
      //   meta: { title: '市南概况' },
      //   component: BlankLayout,
      //   redirect: '/baseInfo/index',
      //   children: [
      //     {
      //       path: 'index',
      //       name: 'BaseInfoIndex',
      //       component: () => import(/* webpackChunkName: "BaseInfo" */ '@/views/baseInfo')
      //     }
      //     // {
      //     //   path: 'info',
      //     //   name: 'BaseInfoInfo',
      //     //   component: () => import(/* webpackChunkName: "BaseInfo" */ '@/views/baseInfo/info')
      //     // },
      //     // {
      //     //   path: 'traffic',
      //     //   name: 'BaseInfoTraffic',
      //     //   component: () => import(/* webpackChunkName: "BaseInfo" */ '@/views/baseInfo/traffic')
      //     // },
      //     // {
      //     //   path: 'facilities',
      //     //   name: 'BaseInfoFacilities',
      //     //   component: () =>
      //     //     import(/* webpackChunkName: "BaseInfo" */ '@/views/baseInfo/facilities')
      //     // },
      //     // {
      //     //   path: 'cost',
      //     //   name: 'BaseInfoCost',
      //     //   component: () => import(/* webpackChunkName: "BaseInfo" */ '@/views/baseInfo/cost')
      //     // }
      //   ]
      // },
      {
        //   path: '/cloudMap2',
        //   name: 'CloudMap2',
        //   meta: { title: '企业云图', rule: ['区领导', '发改局'] },
        //   component: () => import(/* webpackChunkName: "cloudMap" */ '@/views/cloudMap2')
        // },
        // {
        path: '/cloudMap',
        name: 'CloudMap',
        meta: { title: '企业云图', rule: ['区领导', '发改局'] },
        component: () => import(/* webpackChunkName: "cloudMap" */ '@/views/cloudMap')
      },
      {
        path: '/industrialEconomy',
        name: 'IndustrialEconomy',
        meta: { title: '楼宇产业', rule: ['区领导', '发改局'] },
        component: () =>
          import(/* webpackChunkName: "industrialEconomy" */ '@/views/industrialEconomy')
      },
      // {
      //   path: '/buildingTax',
      //   name: 'BuildingTax',
      //   meta: { title: '楼宇税收' },
      //   component: () => import(/* webpackChunkName: "buildingTax" */ '@/views/buildingTax')
      // },
      // {
      //   path: '/buildingTaxNew',
      //   name: 'BuildingTaxNew',
      //   meta: { title: '税源地图', rule: ['区领导', '发改局', '税务局'] },
      //   component: () => import(/* webpackChunkName: "buildingTax" */ '@/views/buildingTaxNew')
      // },
      // {
      //   path: '/buildingTaxNew2',
      //   name: 'BuildingTaxNew2',
      //   meta: { title: '楼宇税收2' },
      //   component: () => import(/* webpackChunkName: "buildingTax" */ '@/views/buildingTax')
      // },
      {
        path: '/buildingResources',
        name: 'BuildingResources',
        meta: { title: '楼宇资源', rule: ['区领导', '发改局'] },
        component: () =>
          import(/* webpackChunkName: "buildingResources" */ '@/views/buildingResources')
      },
      // {
      //   path: '/buildingWorkers',
      //   name: 'BuildingWorkers',
      //   meta: { title: '楼宇人员' },
      //   component: () => import(/* webpackChunkName: "buildingWorkers" */ '@/views/buildingWorkers')
      // },
      // {
      //   path: '/attractInvestment',
      //   name: 'AttractInvestment',
      //   meta: { title: '楼宇招商', rule: ['招商局'] },
      //   component: () =>
      //     import(/* webpackChunkName: "attractInvestment" */ '@/views/attractInvestment')
      // },
      {
        path: '/visit',
        name: 'Visit',
        meta: { title: '企业走访', rule: ['区领导', '发改局', '税务局'] },
        component: () => import(/* webpackChunkName: "buildingTax" */ '@/views/visit')
      },
      {
        path: '/needs',
        name: 'Needs',
        meta: { title: '诉求反馈', rule: ['区领导', '发改局', '税务局'] },
        component: () => import(/* webpackChunkName: "buildingTax" */ '@/views/needs')
      },
      {
        path: '/policy',
        name: 'Policy',
        meta: { title: '政策项目', rule: ['区领导', '发改局', '税务局'] },
        component: () => import(/* webpackChunkName: "buildingTax" */ '@/views/policy')
      }
    ]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  // }
]

export const constantRouterMap = [
  {
    path: '/user',
    component: BlankLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/login')
      }
    ]
  },
  {
    path: '/autologin',
    component: AutoLogin,
    hidden: true,
    name: 'AutoLogin'
  }
]
