// eslint-disable-next-line no-unused-vars
import { createLogger, createStore } from 'vuex'
import industrialEconomy from './modules/industrialEconomy'
import buildingResources from './modules/buildingResources'
import buildingTax from './modules/buildingTax'
import common from './modules/common'
import user from './modules/user'
import cloudMap from './modules/cloudMap'

export default createStore({
  state: {
    isReady: false // 控制页面loading状态,
  },
  mutations: {
    setReadyState: (state, payload) => {
      // console.log(state, payload)
      state.isReady = payload
    }
  },
  actions: {},
  modules: {
    industrialEconomy,
    buildingResources,
    buildingTax,
    user,
    common,
    cloudMap
  }
  // plugins: [createLogger()]
})
