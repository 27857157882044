import { createRouter, createWebHistory } from 'vue-router'

import { constantRouterMap, asyncRouterMap } from '@/config/routerConfig'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRouterMap.concat(asyncRouterMap)
})

export default router
