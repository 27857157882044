/* eslint-disable space-before-function-paren */
import {
  SET_STREET_LIST,
  SET_STREET_PARAMS,
  SET_BUILDING_PARAMS,
  SET_BUILDING_LIST,
  SET_COMMUNITY_LIST,
  SET_COMMUNITY_LIST_PARAMS,
  SET_HOVER_STREET_NAME,
  SET_HOVER_BUILDING_NAME,
  SET_MAP_BUILDING_NAME,
  SET_MAP_STREET_NAME,
  SET_COMPANY_LIST,
  SET_CURRENT_BUILDING_ID,
  SET_CURRENT_STREET_ID,
  SET_CURRENT_STREET_DETAIL,
  SET_CURRENT_BUILDING_DETAIL,
  SET_INDUSTRY_LEVEL_0,
  SET_MAP_LEVEL,
  SET_HOVER_COMMUNITY_NAME,
  SET_MAP_COMMUNITY_NAME,
  SET_MAP_STREET_GEOJSON,
  SET_MAP_STREET_GEOJSON_PARAMS,
  SET_MAP_COMMUNITY_GEOJSON_PARAMS,
  SET_MAP_COMMUNITY_GEOJSON,
  SET_MAP_BUILDING_GEOJSON,
  SET_MAP_BUILDING_GEOJSON_PARAMS,
  SET_CURRENT_COMMUNITY_ID,
  SET_CURRENT_COMMUNITY_DETAIL,
  SET_CURRENT_YEAR,
  SET_CURRENT_QUARTER,
  SET_BUILDING_COMPANY_LIST,
  SET_CURRENT_INDUSTRY_CODE,
  SET_CURRENT_INDUSTRY_TAG,
  SET_CURRENT_INDUSTRY_LABEL
} from '@/store/mutation-types'

import {
  getStreetList,
  getBuildingList,
  getCommunityPageList,
  getCompanyNoLimit,
  getStreetDetail,
  getBuildingDetail,
  getIndustryListLevel0,
  getCommunityDetail
} from '@/api/list'

import { ElMessage } from 'element-plus'
import { formatTax, formatCapital } from '@/utils/util'
import { aggregate, aggregateList } from '@/api/aggregate'
import { local, session } from '@/utils/storage'
import { getStreetPolygon, getCommunityPolygon, getBuildingGeoJson } from '@/api/map'

// const startYear = `${new Date().getFullYear() - 2}年`
const startYear = '2020年'
export default {
  state: () => ({
    currentYear: 2020,
    quarter: '',
    hoverSteetName: '',
    mapStreetName: '',
    hoverBuildingName: '',
    mapBuildingName: '',
    hoverCommunityName: '',
    mapCommunityName: '',
    streetList: [],
    mapStreetGeojson: {},
    mapStreetGeojsonParams: {},
    mapCommunityGeojson: {},
    mapCommunityGeojsonParams: {},
    mapBuildingGeojson: {},
    mapBuildingGeojsonParams: {},
    streetParams: {},
    buildingList: [],
    buildingParams: {},
    communityList: [],
    communityParams: {},
    companyList: null,
    currentStreetId: null,
    currentCommunityId: null,
    currentBuildingId: null,
    currentCommunityDetail: {},
    currentStreetDetail: {},
    currentBuildingDetail: {},
    industryLevel0: null,
    mapLevel: 1,
    buildingCompanyList: {},
    currentIndustryCode: '',
    currentIndustryLabel: '',
    currentIndustryTag: ''
  }),
  mutations: {
    [SET_CURRENT_INDUSTRY_CODE]: (state, payload) => {
      state.currentIndustryCode = payload
    },
    [SET_CURRENT_INDUSTRY_LABEL]: (state, payload) => {
      state.currentIndustryLabel = payload
    },
    [SET_CURRENT_INDUSTRY_TAG]: (state, payload) => {
      state.currentIndustryTag = payload
    },
    [SET_CURRENT_QUARTER]: (state, payload) => {
      state.quarter = payload
    },
    [SET_CURRENT_YEAR]: (state, payload) => {
      state.currentYear = payload
    },
    [SET_MAP_LEVEL]: (state, payload) => {
      state.mapLevel = payload
    },
    [SET_STREET_LIST]: (state, payload) => {
      state.streetList = payload
    },
    [SET_STREET_PARAMS]: (state, payload) => {
      state.streetParams = payload
    },
    [SET_BUILDING_LIST]: (state, payload) => {
      state.buildingList = payload
    },
    [SET_COMMUNITY_LIST]: (state, payload) => {
      state.communityList = payload
    },
    [SET_COMMUNITY_LIST_PARAMS]: (state, payload) => {
      state.communityParams = payload
    },
    [SET_BUILDING_PARAMS]: (state, payload) => {
      state.buildingParams = payload
    },
    [SET_HOVER_STREET_NAME]: (state, payload) => {
      state.hoverSteetName = payload
    },
    [SET_HOVER_BUILDING_NAME]: (state, payload) => {
      state.hoverBuildingName = payload
    },
    [SET_HOVER_COMMUNITY_NAME]: (state, payload) => {
      state.hoverCommunityName = payload
    },
    [SET_MAP_BUILDING_NAME]: (state, payload) => {
      state.mapBuildingName = payload
    },
    [SET_MAP_STREET_NAME]: (state, payload) => {
      state.mapStreetName = payload
    },
    [SET_MAP_COMMUNITY_NAME]: (state, payload) => {
      state.mapCommunityName = payload
    },
    [SET_COMPANY_LIST]: (state, payload) => {
      state.companyList = payload
    },
    [SET_CURRENT_STREET_ID]: (state, payload) => {
      state.currentStreetId = payload
    },
    [SET_CURRENT_COMMUNITY_ID]: (state, payload) => {
      state.currentCommunityId = payload
    },
    [SET_CURRENT_BUILDING_ID]: (state, payload) => {
      state.currentBuildingId = payload
    },
    [SET_CURRENT_COMMUNITY_DETAIL]: (state, payload) => {
      state.currentCommunityDetail = payload
    },
    [SET_CURRENT_STREET_DETAIL]: (state, payload) => {
      state.currentStreetDetail = payload
    },
    [SET_CURRENT_BUILDING_DETAIL]: (state, payload) => {
      state.currentBuildingDetail = payload
    },
    [SET_INDUSTRY_LEVEL_0]: (state, payload) => {
      state.industryLevel0 = payload
    },
    [SET_MAP_STREET_GEOJSON]: (state, payload) => {
      state.mapStreetGeojson = payload
    },
    [SET_MAP_STREET_GEOJSON_PARAMS]: (state, payload) => {
      const { reset, params } = payload
      if (reset) {
        state.mapStreetGeojsonParams = { year: state.mapStreetGeojsonParams.year, ...params }
      } else {
        state.mapStreetGeojsonParams = {
          ...state.mapStreetGeojsonParams,
          ...params
        }
      }
    },
    [SET_MAP_COMMUNITY_GEOJSON]: (state, payload) => {
      state.mapCommunityGeojson = payload
    },
    [SET_MAP_COMMUNITY_GEOJSON_PARAMS]: (state, payload) => {
      const { reset, params } = payload
      if (reset) {
        state.mapCommunityGeojsonParams = { year: state.mapCommunityGeojsonParams.year, ...params }
      } else {
        state.mapCommunityGeojsonParams = {
          ...state.mapCommunityGeojsonParams,
          ...params
        }
      }
    },
    [SET_MAP_BUILDING_GEOJSON]: (state, payload) => {
      state.mapBuildingGeojson = payload
    },
    [SET_MAP_BUILDING_GEOJSON_PARAMS]: (state, payload) => {
      const { reset, params } = payload
      if (reset) {
        state.mapBuildingGeojsonParams = { year: state.mapBuildingGeojsonParams.year, ...params }
      } else {
        state.mapBuildingGeojsonParams = {
          ...state.mapBuildingGeojsonParams,
          ...params
        }
      }
    },
    [SET_BUILDING_COMPANY_LIST]: (state, payload) => {
      state.buildingCompanyList = payload
    }
  },
  actions: {
    async getMapBuildingGeojson({ commit }, params) {
      const geojson = await getBuildingGeoJson()
      const taxData = await getBuildingTaxData(params)
      const newGeojson = await formatBuildingGeojson(geojson, taxData)
      commit(SET_MAP_BUILDING_GEOJSON, newGeojson)
      if (taxData.length === 0) {
        ElMessage('暂无税收数据')
      }
      return newGeojson
    },

    async getMapCommunityGeojson({ commit }, params) {
      const geojson = await getCommunityPolygon()
      const taxData = await getCommunityTaxData(params)
      const newGeojson = await formatCommunityGeojson(geojson, taxData)
      commit(SET_MAP_COMMUNITY_GEOJSON, newGeojson)
      if (taxData.length === 0) {
        ElMessage('暂无税收数据')
      }
      return newGeojson
    },

    async getMapStreetGeojson({ commit }, params) {
      const geojson = await getStreetPolygon()
      const taxData = await getStreetTaxData(params)
      const newGeojson = await formatStreetGeojson(geojson, taxData)
      commit(SET_MAP_STREET_GEOJSON, newGeojson)
      if (taxData.length === 0) {
        ElMessage('暂无税收数据')
      }
      return newGeojson
    },

    async getStreetList({ commit, state }, params) {
      const res = await getStreetList(params)
      const taxData = await getStreetTaxData(state.mapStreetGeojsonParams)
      const result = res.data.map(d => {
        const obj = taxData.find(t => t.id.id === d.id)
        d.data.tax = (obj && obj.tax) || 0
        d.data.counts = (obj && obj.counts) || 0
        return d
      })
      commit(SET_STREET_LIST, result)
    },

    async getBuildingList({ commit, state }, params = {}) {
      const res = await getBuildingList({
        conditions: {
          'data.name': { $exists: 1 }
        },
        fields: {
          data: {
            name: true,
            crd: true,
            street: true,
            leaveArea: true,
            buildingTags: true,
            industryType: true,
            buildingTypeLabel: true
          },
          stats: {
            regCurrentYearTax: true,
            regCurrentYearQxTax: true,
            regCpyCount: true,
            leaveAreaRate: true,
            regEnterCpyCount: true,
            regEnterCurrentYearTax: true,
            regEnterLastYearTax: true,
            InfoCplt: true,
            enterLastYearTax: true,
            enterCurrentYearTax: true,
            regLastYearQxTax: true,
            enterCpyCount: true,
            regLastYearTax: true
          }
        },
        ...params
      })

      const taxData = await getBuildingTaxData(state.mapBuildingGeojsonParams)

      const result = res.data.map(d => {
        const obj = taxData.find(t => t.id.id === d.id)
        d.data.tax = (obj && obj.tax) || 0
        d.stats.tax = (obj && obj.tax) || 0
        d.data.counts = (obj && obj.counts) || 0
        d.data.leaveArea = Number(d.data.leaveArea)
        d.data.leaveAreaRate = Number(d.stats.leaveAreaRate)
        return d
      })
      commit(SET_BUILDING_LIST, result)
      return result
    },

    async getCommunityList({ commit, state }, params = {}) {
      const res = await getCommunityPageList(params)
      const taxData = await getCommunityTaxData(state.mapCommunityGeojsonParams)
      // console.log(res.data, taxData)
      const result = res.data.map(d => {
        const obj = taxData.find(t => t.id.id === d.id)
        d.data.tax = (obj && obj.tax) || 0
        d.data.counts = (obj && obj.counts) || 0
        return d
      })

      // console.log(result)
      commit(SET_COMMUNITY_LIST, result)
    },

    getCompanyList({ commit, state }, params) {
      const localData = session.getItem(local.keys.CLOUD_MAP_COMPANY)
      let minTax = 1000
      let maxTax = 0
      let minCapital = 100
      let maxCapital = 0

      if (!localData) {
        return getCompanyNoLimit(params).then(res => {
          const list = res.data
          const features = list
            .filter(f => !!f.data.regCrd)
            .map(d => {
              const regCrd = d.data.regCrd
              if (typeof regCrd === 'object') {
                const { lon, lat } = regCrd
                const point = [lon, lat]

                const currentTax = formatTax(d.stats?.lastYearTax)
                const currentCapital = formatCapital(d.data?.regCapitalValue)

                minTax = Math.min(minTax, currentTax)
                maxTax = Math.max(maxTax, currentTax)
                minCapital = Math.min(minCapital, currentCapital)
                maxCapital = Math.max(maxCapital, currentCapital)

                return {
                  type: 'Feature',
                  properties: {
                    lastYearTax: currentTax,
                    regCapitalValue: currentCapital
                  },
                  geometry: {
                    type: 'Point',
                    coordinates: point
                  }
                }
              }

              return {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: [0, 0]
                }
              }
            })

          const result = {
            minTax,
            maxTax,
            minCapital,
            maxCapital,
            type: 'FeatureCollection',
            features
          }
          // commit(SET_COMPANY_LIST, result)
          session.setItem(local.keys.CLOUD_MAP_COMPANY, result)
          return result
        })
      }
      return Promise.resolve(localData)
    },

    async getStreetDetail({ commit }, id) {
      const res = await getStreetDetail(id)
      commit(SET_CURRENT_STREET_DETAIL, res.data)
    },

    async getStreetDetailBuilding({ commit, state }, id) {
      const {
        year, // 年份
        quarter, // 季度
        tax, // 税种
        industryTag, // 产业标签，6大产业
        industryType // 国标一级行业
      } = state.mapStreetGeojsonParams

      const groupCmdList = [
        { $match: { 'data.nf': startYear } },
        { $match: { 'street.id': `NumberLong(${id})` } },
        { $match: { 'street.id': { $ne: 'null' } } },
        {
          $group: {
            _id: { id: '$street.id' },
            counts: { $sum: 1 },
            totalTax: { $sum: '$data.totalTax' },
            totalQxTax: { $sum: '$data.totalQxTax' },
            tax: { $sum: '$data.totalTax' }
          }
        }
      ]

      const listCmdList = [
        { $match: { 'data.nf': startYear } },
        { $match: { 'street.id': `NumberLong(${id})` } },
        { $sort: { 'data.totalTax': -1 } },
        { $limit: 10 }
      ]

      if (year) {
        groupCmdList[0].$match['data.nf'] = year
        listCmdList[0].$match['data.nf'] = year
      }
      if (quarter) {
        groupCmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
        listCmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
      }
      if (tax) {
        groupCmdList[groupCmdList.length - 1].$group.tax.$sum = `$data.${tax}`
        listCmdList[listCmdList.length - 2].$sort = { [`data.${tax}`]: -1 }
      }
      if (industryTag) {
        groupCmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
        listCmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
      }
      if (industryType) {
        groupCmdList.splice(1, 0, { $match: { industry: industryType } })
        listCmdList.splice(1, 0, { $match: { industry: industryType } })
      }

      const res = await aggregateList({
        group: {
          cmdList: groupCmdList,
          templateName: 'b_tax_information'
        },
        list: {
          cmdList: listCmdList,
          templateName: 'b_tax_information'
        }
      })
      return res.data
    },

    async getBuildingDetailCompany({ commit, state }, id) {
      const {
        year, // 年份
        quarter, // 季度
        tax, // 税种
        industryTag, // 产业标签，6大产业
        industryType // 国标一级行业
      } = state.mapStreetGeojsonParams

      const groupCmdList = [
        { $match: { 'data.nf': startYear } },
        { $match: { 'building.id': `NumberLong(${id})` } },
        { $match: { 'building.id': { $ne: 'null' } } },
        {
          $group: {
            _id: { id: '$building.id' },
            counts: { $sum: 1 },
            totalTax: { $sum: '$data.totalTax' },
            totalQxTax: { $sum: '$data.totalQxTax' },
            tax: { $sum: '$data.totalTax' }
          }
        }
      ]

      const listCmdList = [
        { $match: { 'data.nf': startYear } },
        { $match: { 'building.id': `NumberLong(${id})` } },
        { $sort: { 'data.totalTax': -1 } },
        { $limit: 10 }
      ]

      if (year) {
        groupCmdList[0].$match['data.nf'] = year
        listCmdList[0].$match['data.nf'] = year
      }
      if (quarter) {
        groupCmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
        listCmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
      }
      if (tax) {
        groupCmdList[groupCmdList.length - 1].$group.tax.$sum = `$data.${tax}`
        listCmdList[listCmdList.length - 2].$sort = { [`data.${tax}`]: -1 }
      }
      if (industryTag) {
        groupCmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
        listCmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
      }
      if (industryType) {
        groupCmdList.splice(1, 0, { $match: { industry: industryType } })
        listCmdList.splice(1, 0, { $match: { industry: industryType } })
      }

      const res = await aggregateList({
        group: {
          cmdList: groupCmdList,
          templateName: 'b_tax_information'
        },
        list: {
          cmdList: listCmdList,
          templateName: 'b_tax_information'
        }
      })
      return res.data
    },

    getBuildingDetail({ commit }, id) {
      getBuildingDetail(id).then(res => {
        commit(SET_CURRENT_BUILDING_DETAIL, res.data)
      })
    },

    async getCommunityDetail({ commit, state }, id) {
      const res = await getCommunityDetail(id)

      const tax = await aggregate({
        cmdList: [
          { $match: { 'community.id': `NumberLong(${id})` } },
          { $match: { 'data.nf': startYear } },
          {
            $group: {
              _id: { id: '$community.id' },
              counts: { $sum: 1 },
              totalTax: { $sum: '$data.totalTax' },
              totalQxTax: { $sum: '$data.totalQxTax' }
            }
          }
        ],
        templateName: 'b_tax_information'
      })

      const result = res.data
      result.data.totalTax = tax.data[0].totalTax
      result.data.totalQxTax = tax.data[0].totalQxTax
      result.data.counts = tax.data[0].counts

      commit(SET_CURRENT_COMMUNITY_DETAIL, result)
    },

    async getCommunityCompanyList({ commit, state }, id) {
      const {
        year, // 年份
        quarter, // 季度
        tax, // 税种
        industryTag, // 产业标签，6大产业
        industryType // 国标一级行业
      } = state.mapCommunityGeojsonParams

      const groupCmdList = [
        { $match: { 'data.nf': startYear } },
        { $match: { 'community.id': `NumberLong(${id})` } },
        { $match: { 'community.id': { $ne: 'null' } } },
        {
          $group: {
            _id: { id: '$community.id' },
            counts: { $sum: 1 },
            totalTax: { $sum: '$data.totalTax' },
            totalQxTax: { $sum: '$data.totalQxTax' },
            tax: { $sum: '$data.totalTax' }
          }
        }
      ]
      const listCmdList = [
        { $match: { 'data.nf': startYear } },
        { $match: { 'community.id': `NumberLong(${id})` } },
        { $sort: { 'data.totalTax': -1 } },
        { $limit: 20 }
      ]

      if (year) {
        groupCmdList[0].$match['data.nf'] = year
        listCmdList[0].$match['data.nf'] = year
      }
      if (quarter) {
        groupCmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
        listCmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
      }
      if (tax) {
        groupCmdList[groupCmdList.length - 1].$group.tax.$sum = `$data.${tax}`
        listCmdList[listCmdList.length - 2].$sort = { [`data.${tax}`]: -1 }
      }
      if (industryTag) {
        groupCmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
        listCmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
      }
      if (industryType) {
        groupCmdList.splice(1, 0, { $match: { industry: industryType } })
        listCmdList.splice(1, 0, { $match: { industry: industryType } })
      }

      const res = await aggregateList({
        group: {
          cmdList: groupCmdList,
          templateName: 'b_tax_information'
        },
        list: {
          cmdList: listCmdList,
          templateName: 'b_tax_information'
        }
      })
      return res.data
    },

    async getBuildingCompanyList({ commit, state }, id) {
      const {
        year, // 年份
        quarter, // 季度
        tax, // 税种
        industryTag, // 产业标签，6大产业
        industryType // 国标一级行业
      } = state.mapCommunityGeojsonParams

      const groupCmdList = [
        { $match: { 'data.nf': startYear } },
        { $match: { 'building.id': `NumberLong(${id})` } },
        {
          $group: {
            _id: { id: '$building.id' },
            counts: { $sum: 1 },
            totalTax: { $sum: '$data.totalTax' },
            totalQxTax: { $sum: '$data.totalQxTax' },
            tax: { $sum: '$data.totalTax' }
          }
        }
      ]
      const listCmdList = [
        { $match: { 'data.nf': startYear } },
        { $match: { 'building.id': `NumberLong(${id})` } },
        { $sort: { 'data.totalTax': -1 } },
        { $limit: 20 }
      ]

      if (year) {
        groupCmdList[0].$match['data.nf'] = year
        listCmdList[0].$match['data.nf'] = year
      }
      if (quarter) {
        groupCmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
        listCmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
      }
      if (tax) {
        groupCmdList[groupCmdList.length - 1].$group.tax.$sum = `$data.${tax}`
        listCmdList[listCmdList.length - 2].$sort = { [`data.${tax}`]: -1 }
      }
      if (industryTag) {
        groupCmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
        listCmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
      }
      if (industryType) {
        groupCmdList.splice(1, 0, { $match: { industry: industryType } })
        listCmdList.splice(1, 0, { $match: { industry: industryType } })
      }

      const res = await aggregateList({
        group: {
          cmdList: groupCmdList,
          templateName: 'b_tax_information'
        },
        list: {
          cmdList: listCmdList,
          templateName: 'b_tax_information'
        }
      })

      commit(SET_BUILDING_COMPANY_LIST, res.data)
      return res.data
    },

    getIndustryLevel0({ commit, state }) {
      if (!state.industryLevel0) {
        getIndustryListLevel0().then(res => {
          commit(SET_INDUSTRY_LEVEL_0, res.data)
        })
      } else {
        commit(SET_INDUSTRY_LEVEL_0, [...state.industryLevel0])
      }
    }
  }
}

const getBuildingTaxData = async (params = {}) => {
  const {
    year, // 年份
    quarter, // 季度
    tax, // 税种
    industryTag, // 产业标签，6大产业
    industryType // 国标一级行业
  } = params

  const cmdList = [
    { $match: { 'data.nf': startYear } },
    // { $match: { 'data.jd': '1季度' } },
    { $match: { 'building.id': { $ne: 'null' } } },
    {
      $group: {
        _id: { id: '$building.id' },
        counts: { $sum: 1 },
        tax: { $sum: '$data.totalTax' }
      }
    }
  ]

  if (year) {
    cmdList[0].$match['data.nf'] = year
  }
  if (quarter) {
    cmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
    // cmdList.push({ $match: { 'data.jd': quarter } })
  }
  if (tax) {
    cmdList[cmdList.length - 1].$group.tax.$sum = `$data.${tax}`
  }
  if (industryTag) {
    cmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
    // cmdList.push({ $match: { industryTag: { $in: industryTag } } })
  }
  if (industryType) {
    cmdList.splice(1, 0, { $match: { industry: industryType } })
    // cmdList.push({ $match: { industry: { $in: industryType } } })
  }

  const res = await aggregate({
    cmdList,
    templateName: 'b_tax_information'
  })
  return res.data
}

const formatBuildingGeojson = async (buildingData, taxDataList) => {
  let min = 1000
  let max = 0
  let minArea = 1000
  let maxArea = 0
  let minAreaRate = 1000
  let maxAreaRate = 0
  const features = buildingData.features.map(d => {
    const obj = taxDataList.find(t => t.id.id === d.properties.id)
    const currentTax = (obj && obj.tax) || 0
    min = Math.min(min, currentTax)
    max = Math.max(max, currentTax)
    minArea = Math.min(minArea, d.properties.leaveArea)
    maxArea = Math.max(maxArea, d.properties.leaveArea)
    minAreaRate = Math.min(minAreaRate, d.properties.leaveAreaRate)
    maxAreaRate = Math.max(maxAreaRate, d.properties.leaveAreaRate)
    d.properties.totalTax = formatTax(currentTax)
    return d
  })

  return {
    type: 'FeatureCollection',
    features,
    minTax: formatTax(min),
    maxTax: formatTax(max),
    minArea,
    maxArea,
    minAreaRate,
    maxAreaRate
  }
}

const getStreetTaxData = async (params = {}) => {
  const {
    year, // 年份
    quarter, // 季度
    tax, // 税种
    industryTag, // 产业标签，6大产业
    industryType // 国标一级行业
  } = params

  const cmdList = [
    { $match: { 'data.nf': startYear } },
    // { $match: { 'data.jd': '1季度' } },
    { $match: { 'street.id': { $ne: 'null' } } },
    {
      $group: {
        _id: { id: '$street.id' },
        counts: { $sum: 1 },
        tax: { $sum: '$data.totalTax' }
      }
    }
  ]

  if (year) {
    cmdList[0].$match['data.nf'] = year
  }
  if (quarter) {
    cmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
    // cmdList.push({ $match: { 'data.jd': quarter } })
  }
  if (tax) {
    cmdList[cmdList.length - 1].$group.tax.$sum = `$data.${tax}`
  }
  if (industryTag) {
    cmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
    // cmdList.push({ $match: { industryTag: { $in: industryTag } } })
  }
  if (industryType) {
    cmdList.splice(1, 0, { $match: { industry: industryType } })
    // cmdList.push({ $match: { industry: { $in: industryType } } })
  }

  const res = await aggregate({
    cmdList,
    templateName: 'b_tax_information'
  })
  return res.data
}

const formatStreetGeojson = async (streetData, taxDataList) => {
  let min = 10000
  let max = 0
  const features = streetData.features.map(d => {
    const obj = taxDataList.find(t => t.id.id === d.properties.streetId)
    const currentTax = (obj && obj.tax) || 0
    min = Math.min(min, currentTax)
    max = Math.max(max, currentTax)
    d.properties.totalTax = formatTax(currentTax)
    return d
  })

  return {
    type: 'FeatureCollection',
    features,
    minTax: formatTax(min),
    maxTax: formatTax(max)
  }
}

const getCommunityTaxData = async (params = {}) => {
  const {
    year, // 年份
    quarter, // 季度
    tax, // 税种
    industryTag, // 产业标签，6大产业
    industryType // 国标一级行业
  } = params

  const cmdList = [
    { $match: { 'data.nf': startYear } },
    // { $match: { 'data.jd': '1季度' } },
    { $match: { 'community.id': { $ne: 'null' } } },
    {
      $group: {
        _id: { id: '$community.id' },
        counts: { $sum: 1 },
        tax: { $sum: '$data.totalTax' }
      }
    }
  ]

  if (year) {
    cmdList[0].$match['data.nf'] = year
  }
  if (quarter) {
    cmdList.splice(1, 0, { $match: { 'data.jd': quarter } })
    // cmdList.push({ $match: { 'data.jd': quarter } })
  }
  if (tax) {
    cmdList[cmdList.length - 1].$group.tax.$sum = `$data.${tax}`
  }
  if (industryTag) {
    cmdList.splice(1, 0, { $match: { industryTag: { $in: industryTag } } })
    // cmdList.push({ $match: { industryTag: { $in: industryTag } } })
  }
  if (industryType) {
    cmdList.splice(1, 0, { $match: { industry: industryType } })
    // cmdList.push({ $match: { industry: { $in: industryType } } })
  }

  const res = await aggregate({
    cmdList,
    templateName: 'b_tax_information'
  })
  return res.data
}

const formatCommunityGeojson = async (communityData, taxDataList) => {
  let min = 10000
  let max = 0
  if (!communityData) {
    debugger
  }
  const features = communityData.features.map(d => {
    const obj = taxDataList.find(t => t.id.id === d.properties.id)
    const currentTax = (obj && obj.tax) || 0
    min = Math.min(min, currentTax)
    max = Math.max(max, currentTax)
    d.properties.totalTax = formatTax(currentTax)
    return d
  })

  return {
    type: 'FeatureCollection',
    features,
    minTax: formatTax(min),
    maxTax: formatTax(max)
  }
}
