export default {
  B_TAX_INFORMATION: 'b_tax_information', // 税源
  BUILDING_INFORMATION: 'building_information', // 楼宇
  ENTERPRISE_INFORMATION: 'enterprise_information', // 企业
  COMMUNITY_INFORMATION: 'community_information', // 社区
  STREET_INFORMATION: 'street_information', // 社区
  RENTING_INFORMATION: 'renting_information', // 房源

  B_CONSOLE_BUILDING: 'b_console_building',
  B_CONSOLE_COMPANY: 'b_console_cpy',
  B_CONSOLE_COMMUNITY: 'b_console_community',
  B_CONSOLE_STREET: 'b_console_street',
  B_CONSOLE_RENTING: 'b_console_renting',
  B_CONSOLE_STATS_CPY: 'b_console_stats_cpy',
  B_CONSOLE_POLICY_PROJECT_APPLY: 'b_console_policy_project_apply'
}

// "street_information"
// "building_information",
// "community_information",
// "building_manager",
// "road_mapping",
// "CustomList",
// "multi_media",
// "basic_policy",
// "renting_information",
// "project_information_final",
// "attract_investment_group",
// "property_information",
// "enterprise_information",
// "accountMenuTemplate",
// "dictionary_data",
// "project_information_draft",
// "orgMenuTemplate",
// "enterprise_information_bk",

// 税源表说明:
//            "zzs",//增值税
//            "xfs",//消费税
//            "qysds",//企业所得税
//            "grsds",//个人所得税
//            "cswhjss",//城市维护建设税
//            "zys",//资源税
//            "fcs",//房产税
//            "yhs",//印花税
//            "cztdsys",//城镇土地使用税
//            "tdzzs",//土地增值税
//            "ccs",//车船税
//            "clgzs",//车辆购置税
//            "gdzys",//耕地占用税
//            "qs",//契税
//            "yys",//烟叶税
//            "hjbhs",//环境保护税
//            "qtsssr",//其他税收收入
//            "jyffj",//教育费附加
//            "dfjyfj",//地方教育附加
//            "sljszxsr",//水利建设专项收入
//            "cjrjybzj",//残疾人就业保障金
//            "qtsr",//其他收入
//            "qxzzs",//区县增值税
//            "qxqysds",//区县企业所得税
//            "qxgrsds",//区县个人所得税
//            "qxcswhjss",//区县城市维护建设税
//            "qxzys",//区县资源税
//            "qxfcs",//区县房产税
//            "qxyhs",//区县印花税
//            "qxcztdsys",//区县城镇土地使用税
//            "qxtdzzs",//区县土地增值税
//            "qxccs",//区县车船税
//            "qxgdzys",//区县耕地占用税
//            "qxqs",//区县契税
//            "qxyys",//区县烟叶税
//            "qxhjbhs",//区县环境保护税
//            "qxqtsssr",//区县其他税收收入
//            "qxjyffj",//区县教育费附加
//            "qxdfjyfj",//区县地方教育附加
//            "qxcjrjybzj",//区县残疾人就业保障金

//            "totalQxTax",//区县总税收
//            "totalTax",//总税收
