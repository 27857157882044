// eslint-disable-next-line no-unused-vars
import http from '@/utils/http'

import { getBaseUrl } from '@/config/apiConfig'
const BASE_URL = getBaseUrl().BASE_URL
// const BASE_URL2 = getBaseUrl().BASE_URL2
const api = {
  login: `${BASE_URL}/sys/auth/login`,
  phoneLogin: `${BASE_URL}/sys/auth/phoneLogin`,
  logout: `${BASE_URL}/sys/auth/logout`,
  detail: `${BASE_URL}/commonInstance/detail`
}

export const getScreenAccountDetail = id => {
  return http({
    url: `${api.detail}/screen_accounts/${id}`,
    method: 'get'
  })
}

export const login = params => {
  return http({
    url: api.login,
    method: 'post',
    data: params
  })
}

export const phoneLogin = params => {
  return http({
    url: api.phoneLogin,
    method: 'post',
    data: params
  })
}

export const logout = () => {
  return http({
    url: api.logout,
    method: 'post'
  })
}
