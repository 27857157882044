import {
  SET_CURRENT_STREET_NAME,
  SET_CURRENT_DATA_DIMENSIONAL,
  SET_CURRENT_RENT_SALE_STATE,
  SET_CURRENT_BUILDING_TAG
} from '@/store/mutation-types'
export default {
  state: () => ({
    currentStreetName: '全部街道',
    currentRentSaleState: 'all', // all:全部, rent:出租， sale:出售
    currentDataDimensional: 'leaveArea', // leaveArea: 闲置面积 leaveAreaRate：闲置占比
    currentBuildingTag: 0 // 0：全部 1：亿元楼宇 2：重点楼宇，3：特色楼宇
  }),
  mutations: {
    [SET_CURRENT_STREET_NAME]: (state, payload) => {
      state.currentStreetName = payload
    },
    [SET_CURRENT_RENT_SALE_STATE]: (state, payload) => {
      state.currentRentSaleState = payload
    },
    [SET_CURRENT_DATA_DIMENSIONAL]: (state, payload) => {
      state.currentDataDimensional = payload
    },
    [SET_CURRENT_BUILDING_TAG]: (state, payload) => {
      state.currentBuildingTag = payload
    }
  },
  actions: {},
  getters: {}
}
