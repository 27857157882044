export const AMAP_KEY = 'f566b5a5112e4bfc60efcea8dea21385'
export const SEARCH_KEY = 'b088fa350ae929ecda68e3933217335d'
export const chartColors = [
  '#1e75ff',
  '#ffc542',
  '#6dd400',
  '#bdd357',
  '#3dd598',
  '#1dcfc1',
  '#ff974a',
  '#6236ff',
  '#7536ff',
  '#32c5ff',
  '#3282ff',
  '#0044ff'
]

// export const mapColors = [
//   '#FFF8B4',
//   '#D3F299',
//   '#9FE084',
//   '#5ACA70',
//   '#00AF53',
//   '#00873A',
//   '#006B31',
//   '#004835',
//   '#003829'
// ]
export const mapColors = [
  '#489657',
  '#7bbb6d',
  '#b1d878',
  '#ddee97',
  '#ffffc6',
  '#f9e196',
  '#f1b16e',
  '#e3754f',
  '#c64032'
]
