export const isIP = () => {
  const reg = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return reg.test(window.location.hostname)
}

console.log('isip', isIP(), typeof window.location.hostname, window.location.hostname)

export const apiConfig = {
  development: {
    // BASE_URL: 'http://47.108.161.74:8005',
    // BASE_URL2: 'http://47.108.161.74:30080/api/tenant'
    // 测试正式数据时使用
    BASE_URL: isIP() ? 'http://15.72.191.145:10003/admin' : 'https://api.snqyfw.com:8008/admin',
    BASE_URL2: isIP()
      ? 'http://15.72.191.145:10003/v2/admin'
      : 'https://api.snqyfw.com:8008/v2/admin'
  },
  production: {
    BASE_URL: isIP() ? 'http://15.72.191.145:10003/admin' : 'https://api.snqyfw.com:8008/admin',
    BASE_URL2: isIP()
      ? 'http://15.72.191.145:10003/v2/admin'
      : 'https://api.snqyfw.com:8008/v2/admin'
  }
}

const env = process.env.NODE_ENV
console.log('current-api', apiConfig[env])
export const getBaseUrl = () => {
  return apiConfig[env]
}
