import router from '@/router'
import store from '@/store'
import storage from 'store'
// import { ElNotification } from 'element-plus'
import { ACCESS_TOKEN, USER_INFO, SET_USER_INFO, SET_TOKEN } from '@/store/mutation-types'

const whiteList = ['Login', 'AutoLogin'] // no redirect whitelist
const loginRoutePath = '/user/login'

router.beforeEach(async (to, from, next) => {
  if (whiteList.includes(to.name)) {
    if (to.name === 'AutoLogin') {
      await store.dispatch('login', {
        account: '市南区大屏',
        password: 'pwd123456'
      })
      next('/home')
    } else {
      next()
    }
  } else {
    if (to.query.account && to.query.password) {
      await store.dispatch('login', {
        account: to.query.account,
        password: to.query.password
      })
    }
    const token = storage.get(ACCESS_TOKEN)
    if (token) {
      // 从storage中获取用户信息存入store中（应该用token向后台请求用户信息）
      const userInfo = storage.get(USER_INFO)
      store.commit(SET_USER_INFO, userInfo)
      store.commit(SET_TOKEN, token)
      next()
    } else {
      next({ path: loginRoutePath, query: to.query })
    }
  }
})

router.afterEach(() => {})
