<template>
  <Container>
    <div class="base-layout-wrapper">
      <div class="base-layout-header">
        <Header />
      </div>
      <div class="base-layout-content">
        <CustomSuspense :isReady="isReady">
          <template #default>
            <router-view></router-view>
          </template>
        </CustomSuspense>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from '@/components/Container'
import Header from '@/components/Header'
import CustomSuspense from '@/components/CustomSuspense'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  name: 'BaseLayout',
  components: { Container, Header, CustomSuspense },
  setup: () => {
    const store = useStore()
    const isReady = computed(() => store.state.isReady)
    return { isReady }
  }
}
</script>

<style lang="less" scoped>
.base-layout-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #13131a;

  .base-layout-header {
    width: 100%;
    height: 75px;
    background-color: #1c1c24;
    margin-bottom: -1px;
  }

  .base-layout-content {
    width: 100%;
    height: calc(100% - 75px);
    background-color: #13131a;
    color: #fff;
    // overflow: hidden;
  }
}
</style>
