export const session = {
  keys: {
    STREET_CENTER: 'street_center', // 街道中心点
    STREET_POLYGON: 'street_polygon', // 街道地图geojson
    COMMUNITY_POLYGON: 'community_polygon', // 社区地图geojson
    BUILDING_POLYGON: 'building_polygon', // 社区地图geojson
    CLOUD_MAP_COMPANY: 'cloud_map_company' // 云图企业数据
  },
  getItem: key => {
    const origin = window.sessionStorage.getItem(key)
    return JSON.parse(origin)
  },
  setItem: (key, data) => {
    const str = JSON.stringify(data)
    window.sessionStorage.setItem(key, str)
  }
}

export const local = {
  keys: {
    CLOUD_MAP_COMPANY: 'cloud_map_company'
  },
  getItem: key => {
    const origin = window.localStorage.getItem(key)
    return JSON.parse(origin)
  },
  setItem: (key, data) => {
    const str = JSON.stringify(data)
    window.localStorage.setItem(key, str)
  }
}
