<template>
  <div class="data-view-common-container" ref="dataView" :style="styleObj">
    <template v-if="isReady">
      <slot></slot>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { debounce } from '@/utils/util'
export default {
  name: 'dataViewCommonContainer',
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup: props => {
    const baseWidth = 1920
    const baseHeight = 1080
    const dataView = ref()
    const isReady = ref(false)
    const styleObj = reactive({
      width: `${baseWidth}px`,
      height: `${baseHeight}px`
    })

    const initSize = () => {
      // 初始化容器尺寸
      const { width, height } = props.options

      const targetWidth = width || window.screen.width
      const targetHeight = height || window.screen.height
      styleObj.transform = `scale(${targetWidth / baseWidth},${targetHeight / baseHeight})`
    }

    const updateScale = () => {
      const { width, height } = props.options
      const targetWidth = width || document.body.clientWidth
      const targetHeight = height || document.body.clientHeight
      styleObj.transform = `scale(${targetWidth / baseWidth},${targetHeight / baseHeight})`
    }

    const resize = debounce(updateScale)

    onMounted(() => {
      initSize()
      updateScale()
      isReady.value = true
      window.addEventListener('resize', resize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', resize)
    })
    return { dataView, styleObj, isReady }
  }
}
</script>

<style lang="less" scoped>
.data-view-common-container {
  transform-origin: left top;
}
</style>
