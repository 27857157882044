import { createApp } from 'vue'
import CountTo from 'vue-count-to2'
import 'reset-css'
import './assets/styles/reset.less'

import App from './App.vue'
import router from './router'
import store from './store'
import VEchart from 'vue-echarts'
import '@/utils/importEchart'
import elementPlus from './utils/importElement'
import 'default-passive-events'
import '@/utils/permission'

import { bindAjaxHook } from '@/utils/ajaxhook'
bindAjaxHook()
createApp(App)
  .use(store)
  .use(router)
  .component('VEchart', VEchart)
  .use(elementPlus)
  .use(CountTo)
  .mount('#app')
