<template>
  <router-view />
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { isIP } from '@/config/apiConfig'
// const s = document.createElement('script')
// s.src = 'https://webapi.amap.com/loader.js'

export default {
  setup: () => {
    const s2 = document.createElement('script')
    s2.src = isIP()
      ? 'http://15.72.163.216:20002/alicdn/t/font_2484910_o0011sdmx8b.js'
      : 'https://at.alicdn.com/t/font_2484910_o0011sdmx8b.js'
    document.body.appendChild(s2)

    return {}
  }
}
</script>
