import { SET_CURRENT_MENU_INDUSTRY, SET_CURRENT_INDUSTRY } from '@/store/mutation-types'
import { btnDataMap } from '@/utils/locaData'
const componentMap = {
  street: 'StreetList',
  building: 'BuildingList',
  company: 'CompanyList'
}

export default {
  state: () => ({
    left2ChartData: [],
    left3ChartData: [],
    currentMenu: 'StreetList', // 当前二级菜单
    currentIndustry: btnDataMap.company[0].label, // 当前产业标签
    currentIndustryName: btnDataMap.company[0].name // 当前产业标签
  }),
  mutations: {
    [SET_CURRENT_MENU_INDUSTRY]: (state, payload) => {
      state.currentMenu = componentMap[payload]
    },
    [SET_CURRENT_INDUSTRY]: (state, payload) => {
      state.currentIndustry = payload
      const temp = btnDataMap.company.find(d => d.label === payload)
      state.currentIndustryName = temp.name
    },
    setLeft2ChartData: (state, payload) => {
      state.left2ChartData = payload
    },
    setLeft3ChartData: (state, payload) => {
      state.left3ChartData = payload
    }
  },
  actions: {},
  getters: {}
}
