<template>
  <div class="loading-wrapper" :style="{ backgroundColor: bgc }">
    <svg :width="`${width}px`" :height="`${height}px`">
      <circle
        :cx="width / 2"
        :cy="height / 2"
        :r="width * 0.45"
        fill="transparent"
        :stroke-width="strokeWidth"
        :stroke-dasharray="bigDasharray"
        stroke="#02bcfe"
        stroke-linecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          :values="bigValues"
          dur="1.5s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke"
          values="#02bcfe;#3be6cb;#02bcfe"
          dur="3s"
          repeatCount="indefinite"
        />
      </circle>

      <circle
        :cx="width / 2"
        :cy="height / 2"
        :r="width * 0.25"
        fill="transparent"
        :stroke-width="strokeWidth"
        :stroke-dasharray="smallDasharray"
        stroke="#3be6cb"
        stroke-linecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          :values="smallValues"
          dur="1.5s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke"
          values="#3be6cb;#02bcfe;#3be6cb"
          dur="3s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
    <div class="loading-tip">
      <slot />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'Loading',
  props: {
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 50
    },
    strokeWidth: {
      type: Number,
      default: 3
    },
    bgc: {
      type: String,
      default: '#0b0f1a'
    }
  },

  setup: props => {
    const bigValues = computed(
      () => `0, ${props.width / 2} ${props.height / 2}; 360, ${props.width / 2} ${props.height / 2}`
    )
    const smallValues = computed(
      () => `360, ${props.width / 2} ${props.height / 2}; 0, ${props.width / 2} ${props.height / 2}`
    )

    const bigDasharray = computed(
      () => `${(props.width * 0.45 * 2 * Math.PI) / 4} ${(props.height * 0.45 * 2 * Math.PI) / 4}`
    )

    const smallDasharray = computed(
      () => `${(props.width * 0.25 * 2 * Math.PI) / 4} ${(props.height * 0.25 * 2 * Math.PI) / 4}`
    )

    return { bigValues, smallValues, bigDasharray, smallDasharray }
  }
}
</script>

<style lang="less">
@bgc: #0b0f1a;
// @bgc: #161e31;
.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: @bgc;
  .loading-tip {
    font-size: 14px;
    margin-top: 5px;
    color: #fff;
  }
}
</style>
