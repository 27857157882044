<template>
  <div class="nav-bar-wrapper">
    <!-- <router-link class="nav-item" v-for="nav in dataList" :key="nav.name" :to="nav.path">
      {{ nav.name }}
    </router-link> -->

    <router-link
      v-for="nav in menus"
      :key="nav.name"
      :to="nav.path"
      custom
      v-slot="{ href, route, navigate, isActive }"
    >
      <div class="link-item">
        <a :href="href" @click="navigate" :class="{ active: isActive }">{{ route.meta.title }}</a>
      </div>
    </router-link>
  </div>
</template>

<script>
import { asyncRouterMap } from '@/config/routerConfig'
// eslint-disable-next-line no-unused-vars
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'NavBar',
  setup: () => {
    // eslint-disable-next-line no-unused-vars
    const store = useStore()
    const menus = ref([])

    menus.value = asyncRouterMap[0].children
    // const ruleType = computed(() => store.getters.ruleType)
    menus.value = asyncRouterMap[0].children.filter(d => {
      // const rule = d.meta.rule
      return true // !rule || rule.includes(ruleType.value)
    })
    return {
      menus
    }
  }
}
</script>

<style lang="less" scoped>
.nav-bar-wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  .link-item {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  a {
    font-size: 18px;
    color: #999;
    margin: 0 23px;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 3px;
      transform: translateX(-50%);
      width: 0%;
      height: 3px;
      transition: all 0.5s;
    }
  }

  .active {
    color: #1e75ff;
    transition: all 0.5s;
    &::after {
      transition: all 0.5s;
      width: 60%;
      background-color: #1e75ff;
    }
  }
}
</style>
