/* eslint-disable no-unused-vars */
import http from '@/utils/http'
import { getStreetList, getCommunityPageList, getBuildingList } from '@/api/list'
import { SEARCH_KEY } from '@/config/commonConfig'
import { wgs84ToGaode, formatTax } from '@/utils/util'
import snqPolygon from '@/utils/snqPolygon'
import { session } from '@/utils/storage'
// 3万企业坐标点数据
// import { streetPolygon } from '@/utils/locaData'
const api = {
  company6w: '/company_6w_point.json',
  company3w: '/company_3w.json',
  companyin: '/company_in.json',
  streetAreaJson: '/snq_street_amap.json',
  streetPolygon: './snq_street_polygon_amap.json',
  streetCenter: './snq_street_center_amap.json',
  streetAreaCenter_wgs84: '/snq_street_wgs84.geojson',
  buildingList: '/building_shinanqu_position.json',
  buildingGeoJson: '/building.geojson',
  qingdaoGeoJson: '/qingdao.geojson',
  poi: 'https://restapi.amap.com/v3/place/text',
  school: '/poi_school.json',
  shopping: '/poi_shopping.json',
  hospital: '/poi_hospital.json',
  park: '/poi_park.json',
  road: '/snq_road_amap.json',
  bus: '/snq_bus_wgs84.json',
  community: '/snq_community_amap.json',
  communityLine: '/snq_community_amap_line.json'
}

// 6.5万企业坐标点数据
export const getCompanyPoint = () => {
  return new Promise((resolve, reject) => {
    const allCompanyPointStr = localStorage.getItem('allCompanyPoint')

    if (!allCompanyPointStr) {
      http.get(api.company6w).then(res => {
        const result = res.map(d => ({
          lnglat: [d.lng, d.lat]
        }))

        localStorage.setItem('allCompanyPoint', JSON.stringify(result))

        resolve(result)
      })
    } else {
      resolve(JSON.parse(allCompanyPointStr))
    }
  })
}

export const getCompanyGeoJson2 = () => {
  return new Promise(resolve => {
    http.get(api.company3w).then(res => {
      const features = res.map(d => {
        const point = [d.lng, d.lat]
        return {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: point
          }
        }
      })

      const result = {
        type: 'FeatureCollection',
        features
      }

      resolve(result)
    })
  })
}

export const getCompanyGeoJson = (params = {}) => {
  const { type = 'industry0' } = params
  // 全部产业
  if (type === 'industry0') {
    return new Promise(resolve => {
      http.get(api.companyin).then(res => {
        // const arr = res.filter(d => {
        //   const point = [d.lng, d.lat]
        //   return window.AMap.GeometryUtil.isPointInPolygon(point, snqPolygon)
        // })

        // console.log(JSON.stringify(arr), arr.length)

        const features = res.map(d => {
          const point = [d.lng, d.lat]

          // console.log(
          //   'window.AMap.GeometryUtil.isPointInPolygon(point, streetPolygon',
          //   window.AMap.GeometryUtil.isPointInPolygon(point, streetPolygon)
          // )

          return {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: point
            }
          }
        })

        const result = {
          type: 'FeatureCollection',
          features
        }

        resolve(result)
      })
    })
  } else {
    // 其他产业随机返回
    return new Promise(resolve => {
      http.get(api.companyin).then(res => {
        const arr = res.filter(d => Math.random() >= 0.8)
        const features = arr.map(d => {
          const point = [d.lng, d.lat]
          return {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: point
            }
          }
        })

        const result = {
          type: 'FeatureCollection',
          features
        }

        resolve(result)
      })
    })
  }
}
// 市南街道范围geojson，包括中心点坐标
export const getStreetAreaJson = () => {
  // return new Promise(resolve => {
  //   http.get(api.streetAreaCenter_wgs84).then(d => {
  //     console.log(d)
  //     const data = wgs84ToGaode(d)
  //     resolve(data)
  //   })
  // })

  return http.get(api.streetAreaJson)
}
// 市南区街道范围线,结合街道接口增加街道ID
export const getStreetPolygon = () => {
  const polygon = session.getItem(session.keys.STREET_POLYGON)
  if (polygon) return Promise.resolve(polygon)

  const promiseGeojsonStreet = http.get(api.streetPolygon)
  const promiseApiStreet = getStreetList()

  return Promise.all([promiseGeojsonStreet, promiseApiStreet]).then(
    ([geojsonStreet, apiStreet]) => {
      geojsonStreet.features.forEach(f => {
        const name = f.properties.streetName
        const apiStreetItem = apiStreet.data.find(d => d.data.name.includes(name))

        if (apiStreetItem) {
          const id = apiStreetItem.id
          f.properties.streetId = id
        }
      })
      session.setItem(session.keys.STREET_POLYGON, geojsonStreet)
      return geojsonStreet
    }
  )
}

export const getCommunityPolygon = async () => {
  const polygon = session.getItem(session.keys.COMMUNITY_POLYGON)
  if (polygon) return Promise.resolve(polygon)
  const promiseGeojsonCommunity = http.get(api.community)
  const promiseApiCommunity = getCommunityPageList()

  const result = await Promise.all([promiseGeojsonCommunity, promiseApiCommunity]).then(
    ([geojsonCommunity, apiCommunity]) => {
      geojsonCommunity.features.forEach(f => {
        const name = f.properties.name
        const apiCommunityItem = apiCommunity.data.find(d => d.data.name === name)
        if (apiCommunityItem) {
          const id = apiCommunityItem.id
          f.properties.id = id
          f.properties.streetId = apiCommunityItem.data.street
        } else {
          console.log(name + '--没有在接口列表中找到该社区')
        }

        session.setItem(session.keys.COMMUNITY_POLYGON, geojsonCommunity)
      })
      return geojsonCommunity
    }
  )

  return result
}

// 市南区街道中心点
export const getStreetCenter = () => {
  const point = session.getItem(session.keys.STREET_CENTER)
  if (point) return Promise.resolve(point)
  // return http.get(api.streetCenter)

  const promiseGeojsonStreet = http.get(api.streetCenter)
  const promiseApiStreet = getStreetList()

  return Promise.all([promiseGeojsonStreet, promiseApiStreet]).then(
    ([geojsonStreet, apiStreet]) => {
      geojsonStreet.features.forEach(f => {
        const name = f.properties.streetName
        const apiStreetItem = apiStreet.data.find(d => d.data.name.includes(name))

        if (apiStreetItem) {
          const id = apiStreetItem.id
          f.properties.streetId = id
        }
      })
      session.setItem(session.keys.STREET_CENTER, geojsonStreet)
      return geojsonStreet
    }
  )
}
// 市南楼宇数据
export const getBuildingCluster = () => {
  return new Promise(resolve => {
    http.get(api.buildingGeoJson).then(json => {
      const result = json.features.map(d => ({
        lnglat: d.geometry.coordinates,
        ...d.properties
      }))
      resolve(result)
    })
  })
}
// 市南楼宇geojson,通过接口数据生成geojson数据
export const getBuildingGeoJson = () => {
  const point = session.getItem(session.keys.BUILDING_POLYGON)
  if (point) return Promise.resolve(point)
  return getBuildingList({
    conditions: { 'data.crd': { $exists: true } },
    fields: {
      data: {
        name: true,
        crd: true,
        street: true,
        streetLabel: true,
        leaveArea: true,
        industryType: true,
        industryTypeLabel: true,
        buildingTags: true,
        buildingTypeLabel: true
      },
      stats: {
        leaveAreaRate: true,
        regCurrentYearTax: true,
        regCurrentYearQxTax: true,
        regLastYearTax: true,
        regCpyCount: true
      }
    }
  }).then(res => {
    const list = res.data

    const features = list.map(d => {
      const { lon, lat } = d.data.crd
      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [lon, lat] },
        properties: {
          id: d.id,
          buildingName: d.data.name,
          streetId: d.data.street,
          streetName: d.data.streetLabel,
          regCpyCount: Number(d.stats.regCpyCount) || 0,
          regCurrentYearTax: formatTax(d.stats.regCurrentYearTax),
          regCurrentYearQxTax: formatTax(d.stats.regCurrentYearQxTax),
          regLastYearTax: formatTax(d.stats.regLastYearTax),
          industryTypeLabel: d.data.industryTypeLabel,
          industryType: d.data.industryType,
          leaveAreaRate: Number(d.stats.leaveAreaRate),
          leaveArea: Number(d.data.leaveArea),
          buildingTags: d.data.buildingTags,
          buildingTypeLabel: d.data.buildingTypeLabel
        }
      }
    })

    const result = {
      type: 'FeatureCollection',
      features
    }

    session.setItem(session.keys.BUILDING_POLYGON, result)
    return result
  })
  // return http.get(api.buildingGeoJson)
}
// 青岛范围线
export const getQingdaoGeoJson = () => {
  return http.get(api.qingdaoGeoJson)
}

/**
 *  高德poi点搜索 see: https://lbs.amap.com/api/webservice/guide/api/search#instructions
 * @param {Objece} params
 * @param {Objece} params.key
 * @param {Objece} params.city 查询城市 可选值：城市中文、中文全拼、citycode、adcode
 * @param {Objece} params.citylimit 仅返回指定城市数据 可选值：true/false
 * @param {Objece} params.keywords 查询关键字 规则： 多个关键字用“|”分割
 * @param {Objece} params.types 查询POI类型 可选值：分类代码 或 汉字（若用汉字，请严格按照附件之中的汉字填写） https://lbs.amap.com/api/webservice/download
 * @param {Objece} params.children 是否按照层级展示子POI数据 可选值：children=1 当为0的时候，子POI都会显示。当为1的时候，子POI会归类到父POI之中。仅在extensions=all的时候生效
 * @param {Objece} params.offset 每页记录数据 强烈建议不超过25，若超过25可能造成访问报错
 * @param {Objece} params.page 当前页数 最大翻页数100
 * @param {Objece} params.extensions 返回结果控制 此项默认返回基本地址信息；取值为all返回地址信息、附近POI、道路以及道路交叉口信息。
 */
const defaultParams = {
  key: SEARCH_KEY,
  city: '370202',
  citylimit: true
}
export const getPOI = (params = {}) => {
  // demo: https://restapi.amap.com/v3/place/text?key=b088fa350ae929ecda68e3933217335d&types=%E5%AD%A6%E6%A0%A1&city=370202&citylimit=true&children=1&offset=400&page=1&extensions=base
  const locaParams = { ...defaultParams, ...params }

  return http.get(api.poi, {
    params: locaParams
  })
}

export const getSchool = () => {
  return http.get(api.school)
}

export const getShopping = () => {
  return http.get(api.shopping)
}

export const getHospital = () => {
  return http.get(api.hospital)
}

export const getPark = () => {
  return http.get(api.park)
}

// 市南区路网数据
export const getRoad = () => {
  return http.get(api.road)
}

// 市南区公交路线数据
export const getBus = () => {
  // return new Promise(resolve => {
  //   http.get(api.bus).then(d => {
  //     const data = wgs84ToGaode(d)
  //     resolve(data)
  //   })
  // })
  return http.get(api.bus)
}

// 市南区社区数据
export const getCommunity = () => {
  return http.get(api.community)
}

export const getCommunityLine = () => {
  return http.get(api.communityLine)
}
