import { proxy } from 'ajax-hook'
import { isIP } from '@/config/apiConfig'
let unBind = null
export const bindAjaxHook = () => {
  unBind && unBind()
  const { unProxy } = proxy({
    // 请求发起前进入
    onRequest: (config, handler) => {
      console.log(config.url)
      const urls = [
        { split: 'vdata.amap.com', url: 'https://vdata.amap.com', path: 'vdata' },
        { split: 'vdata01.amap.com', url: 'https://vdata01.amap.com', path: 'vdata01' },
        { split: 'vdata02.amap.com', url: 'https://vdata02.amap.com', path: 'vdata02' },
        { split: 'vdata03.amap.com', url: 'https://vdata03.amap.com', path: 'vdata03' },
        { split: 'vdata04.amap.com', url: 'https://vdata04.amap.com', path: 'vdata04' },
        { split: 'vdata05.amap.com', url: 'https://vdata05.amap.com', path: 'vdata05' }
      ]
      if (isIP()) {
        urls.forEach(urlObj => {
          if (config.url.includes(urlObj.url)) {
            config.url = `http://15.72.163.216:20002/${urlObj.path}${
              config.url.split(urlObj.split)[1]
            }`
            console.log(
              '%c [ config.url ]-9',
              'font-size:13px; background:pink; color:#bf2c9f;',
              config.url
            )
          }
        })
      }
      handler.next(config)
    }
    // // 请求发生错误时进入，比如超时；注意，不包括http状态码错误，如404仍然会认为请求成功
    // onError: (err, handler) => {
    //   console.log(err.type)
    //   handler.next(err)
    // },
    // // 请求成功后进入
    // onResponse: (response, handler) => {
    //   console.log(response.response)
    //   handler.next(response)
    // }
  })

  unBind = unProxy
}
