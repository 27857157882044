<template>
  <div class="home-wrapper">
    <div class="mast">
      <div class="text-box">
        <div class="title">
          <span class="qingdao">青岛市</span>
          <i class="point"></i>
          <span class="shinanqu">市南区</span>
        </div>
        <div class="sub-title">Shinan District, Qingdao City</div>

        <div class="sub-desc">
          <div class="part1">
            市南区位于青岛市市区南部滨海一线，是青岛市重要的中心城区，承接了最为优质的资源禀赋、区位优势、发展环境，是中央、省驻青机构、驻青部队机关和青岛市委、市人大、市政府、市政协机关主要所在地，是2008年北京奥运会帆船比赛、2018年上合组织青岛峰会和海军成立70周年多国海军活动举办地。辖区面积30.01平方公里，辖11个街道办事处、50个社区居委会，常住人口48.66万。
          </div>

          <div class="part2">
            2022年生产总值1496.36亿元，同比增长4.7%；社会消费品零售总额720.8亿元。加快新旧动能转换，总部经济、时尚商业、金融商务、现代旅游等发展迅速，第三产业增加值1340.38亿元，同比增长3.6%；三产占比89.6%，位居十区市首位。作为全省面积最小的县（市区），以全市2.7‰的土地，实现了全市10%的经济总量，每平方公里生产总值达49.9亿元，居副省级城市中心城区前列。
          </div>
        </div>
      </div>
      <div class="author" v-if="author">
        摄影师：<span class="author-name">{{ author }}</span>
      </div>
      <el-space class="video-vr" :size="20">
        <div class="video btn" @click="handleVideoBtn">
          <!-- <Icon class="btn-icon" name="play" />
          <span>观看视频</span> -->
          <img class="btn-img" src="@/assets/images/home_video.png" />
        </div>
        <div class="vr btn" @click="handleVRBtn">
          <!-- <Icon class="btn-icon" name="feiji" />
          <span>720全景</span> -->
          <img class="btn-img" src="@/assets/images/home_vr.png" />
        </div>
      </el-space>
    </div>

    <Swiper
      effect="fade"
      :slides-per-view="1"
      :autoplay="{
        delay: 3000
      }"
      loop
      :pagination="{ clickable: true }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <SwiperSlide v-for="item in dataList" :key="item.imgSrc">
        <div class="slide-box">
          <el-image class="slide-img" :src="item.imgSrc" fit="cover" />
        </div>
      </SwiperSlide>
    </Swiper>
  </div>

  <Modal :isShow="showVideo">
    <div class="modal-box">
      <div class="title-box">
        <span class="title">市南区宣传片</span>
        <Icon class="close" name="close" @click="hanldeCloseBtn" />
      </div>
      <div class="content-box">
        <video controls autoplay>
          <source src="https://softya-prod.oss-cn-chengdu.aliyuncs.com/snq/video/snq_home.mp4" />
        </video>
      </div>
    </div>
  </Modal>

  <Modal :isShow="showVR">
    <div class="modal-box">
      <div class="title-box">
        <span class="title">市南区720全景</span>
        <Icon class="close" name="close" @click="hanldeCloseBtn" />
      </div>
      <div class="content-box">
        <iframe
          src="https://720yun.com/t/01vktmf9r1m"
          frameborder="0"
          width="100%"
          height="100%"
          allowFullScreen="true"
        ></iframe>
      </div>
    </div>
  </Modal>
</template>

<script>
import Icon from '@/components/IconSvg'
import useLoading from '@/hooks/useLoading'
import Modal from '@/components/Modal'
// see: https://swiperjs.com/vue
import SwiperCore, { EffectFade, Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.less'
import 'swiper/components/effect-fade/effect-fade.less'
import 'swiper/components/pagination/pagination.less'

import { ref, watch } from 'vue'

SwiperCore.use([EffectFade, Autoplay, Pagination])

const dataList = [
  {
    imgSrc: 'https://softya-prod.oss-cn-chengdu.aliyuncs.com/dataview/home_bg_5.jpg',
    author: '刘明元'
  },
  {
    imgSrc: 'https://softya-prod.oss-cn-chengdu.aliyuncs.com/dataview/home_bg_6.jpg',
    author: '宋喆'
  },
  {
    imgSrc: 'https://softya-prod.oss-cn-chengdu.aliyuncs.com/dataview/home_bg_7.jpg',
    author: '唐婕'
  }
  // {
  //   imgSrc: 'https://softya-prod.oss-cn-chengdu.aliyuncs.com/dataview/home_bg_4.jpg',
  //   author: '杨雪梅'
  // }
]
export default {
  name: 'Home',
  components: { Swiper, SwiperSlide, Icon, Modal },
  setup: () => {
    const activeIndex = ref(1)
    const showVideo = ref(false)
    const showVR = ref(false)
    const author = ref(dataList[0].author)

    const { hidLoading } = useLoading()
    hidLoading()

    // 显示摄影师名称
    const len = dataList.length
    watch(activeIndex, val => {
      if (val > 3) {
        author.value = dataList[(val % len) - 1]?.author
      } else {
        author.value = dataList[val - 1]?.author
      }
    })

    const onSwiper = () => {}
    const onSlideChange = e => {
      activeIndex.value = e.activeIndex
    }
    const handleVideoBtn = () => {
      showVideo.value = true
    }

    const handleVRBtn = () => {
      showVR.value = true
    }

    const hanldeCloseBtn = () => {
      showVideo.value = false
      showVR.value = false
    }
    return {
      showVideo,
      showVR,
      onSwiper,
      onSlideChange,
      dataList,
      author,
      handleVRBtn,
      handleVideoBtn,
      hanldeCloseBtn
    }
  }
}
</script>

<style lang="less" scoped>
@textColor: fade(#fff, 85%);
@titleBgc: #1b1c1c;

.modal-box {
  display: flex;
  flex-direction: column;

  .title-box {
    height: 45px;
    color: @textColor;
    background-color: @titleBgc;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .close {
      width: 15px;
      height: 15px;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }

  .content-box {
    width: 1430px;
    height: 805px;
    & > iframe {
      width: 100%;
      height: 805px;
    }

    & > video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    & > video:focus {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
}

.home-wrapper {
  position: relative;
  height: 100%;

  .swiper-container {
    widows: 100%;
    height: 100%;

    .slide-box {
      height: 100%;
      width: 100%;

      .slide-img {
        height: 100%;
        width: 100%;
      }
    }
  }

  :deep(.swiper-pagination-bullet) {
    background: #fff !important;
  }

  :deep(.swiper-pagination-bullets) {
    width: auto;
    bottom: 70px;
    left: 138px;
  }

  .mast {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(rgba(15, 21, 36, 0.5), rgba(15, 21, 36, 0.2));
    z-index: 2;

    .text-box {
      color: #fff;
      padding-left: 126px;

      .sub-desc {
        font-size: 24px;
        width: 903px;
        line-height: 2;
        margin-top: 30px;
        letter-spacing: 2px;
        font-weight: 600;

        .part1 {
          margin-bottom: 30px;
          text-indent: 2em;
        }

        .part2 {
          text-indent: 2em;
        }
      }

      .title {
        font-size: 64px;
        margin-top: 120px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        .qingdao {
          font-weight: 100;
        }

        .point {
          content: ' ';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: inline-block;
          background-color: #fff;
          margin: 0 15px;
        }

        .shinanqu {
          font-weight: 500;
        }
      }

      .sub-title {
        font-size: 24px;
      }
    }

    .author {
      position: absolute;
      left: 15px;
      bottom: 15px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);

      .author-name {
        display: inline-block;
        width: 80px;
      }
    }

    .video-vr {
      position: absolute;
      right: 60px;
      bottom: 50px;

      .btn {
        padding: 8px 15px;
        border-radius: 4px;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: transparent;
        transition: all 0.5s;

        .btn-icon {
          width: 30px;
          height: 30px;
          margin-right: 15px;
          color: #fff;
          transition: all 0.5s;
        }

        .btn-img {
          height: 60px;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          transition: all 0.5s;
          .btn-icon {
            color: red;
            transition: all 0.5s;
          }
        }
      }

      .video {
        .btn-icon {
          width: 29px !important;
          height: 29px !important;
        }
      }
    }
  }
}
</style>
