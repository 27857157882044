import { SET_CURRENT_COMPANY_KEY } from '@/store/mutation-types'

export default {
  state: () => ({
    currentCompanyKey: 0
  }),
  mutations: {
    [SET_CURRENT_COMPANY_KEY]: (state, payload) => {
      state.currentCompanyKey = payload
    }
  },
  actions: {},
  getters: {}
}
