<template>
  <!-- <Container v-if="showModal"> -->
  <teleport to="#modals" v-if="showModal">
    <div class="modal-content" :class="{ visible: active }">
      <slot />
    </div>
  </teleport>
  <!-- </Container> -->
</template>

<script>
import { ref, watch } from 'vue'
// import Container from '@/components/Container'
export default {
  name: 'Modal',
  components: {},
  props: {
    isShow: Boolean
  },
  setup: props => {
    const active = ref(false)
    const showModal = ref(false)

    watch(
      () => props.isShow,
      val => {
        showModal.value = val
        if (val) {
          setTimeout(() => {
            active.value = true
          }, 500)
        } else {
          active.value = false
        }
      }
    )

    const show = () => {
      showModal.value = true
      setTimeout(() => {
        active.value = true
      }, 500)
    }

    const hid = () => {
      showModal.value = false
      active.value = false
    }

    return { active, showModal, show, hid }
  }
}
</script>

<style lang="less" scoped>
@bgc: fade(#000, 80%);
.visible {
  opacity: 1 !important;
  transition: all 0.5s !important;
}
.modal-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: @bgc;
  opacity: 0;
  transition: all 0.5s;
}
</style>
