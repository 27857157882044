import {
  SET_STREET_PERCENT_DATA,
  SET_PROJECT_PERCENT_DATA,
  SET_CURRENT_YEAR_INDEX,
  SET_YEAR_LIST,
  SET_CURRENT_INDUSTRY,
  SET_CURRENT_MENU_TAX,
  SET_INTERVAL
} from '@/store/mutation-types'

const componentMap = {
  street: 'StreetList',
  building: 'BuildingList',
  company: 'CompanyList'
}

const getYearList = () => {
  const current = new Date().getFullYear()
  const list = [current]
  ;[1, 2, 3, 4].forEach(y => {
    list.push(current - y)
  })
  return list.reverse().map(d => String(d))
}

export default {
  state: () => ({
    streetPercentData: [], // 左侧图表2 街道占比数据
    projectPercentData: [], // 左侧图表3 项目占比数据
    yearList: getYearList(),
    currentYearIndex: 0, // 当前年份索引值
    currentIndustry: 'industry0', // 当前产业标签
    currentMenu: 'StreetList', // 当前二级菜单
    interval: 3000
  }),
  mutations: {
    [SET_STREET_PERCENT_DATA]: (state, payload) => {
      state.streetPercentData = payload
    },
    [SET_PROJECT_PERCENT_DATA]: (state, payload) => {
      state.projectPercentData = payload
    },
    [SET_CURRENT_YEAR_INDEX]: (state, payload) => {
      state.currentYearIndex = payload
    },
    [SET_YEAR_LIST]: (state, payload) => {
      state.yearList = payload
    },
    [SET_CURRENT_INDUSTRY]: (state, payload) => {
      state.currentIndustry = payload
    },
    [SET_CURRENT_MENU_TAX]: (state, payload) => {
      state.currentMenu = componentMap[payload]
    },
    [SET_INTERVAL]: (state, payload) => {
      state.interval = payload
    }
  },
  actions: {},
  getters: {}
}
