<template>
  <div class="header-wrapper">
    <div class="logo">
      <img src="@/assets/logo.png" alt="市南区企业服务智慧平台（楼宇经济平台）" />
    </div>
    <div class="nav">
      <NavBar />
    </div>
    <div class="login">
      <el-dropdown @command="handleCommand">
        <div class="el-dropdown-link">
          <Icon name="yonghu" :style="{ width: '25px', height: '25px' }" />
          <div class="user-name">{{ userName }}</div>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="logout">登出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar'
import Icon from '@/components/IconSvg'
import { useStore } from 'vuex'
// eslint-disable-next-line no-unused-vars
import { computed, watch, ref } from 'vue'
export default {
  name: 'Header',
  components: { NavBar, Icon },
  setup: () => {
    const store = useStore()
    const userName = computed(() => store.getters.userName)

    const handleCommand = command => {
      switch (command) {
        case 'logout':
          store.dispatch('logout')
          break
      }
    }
    return { userName, handleCommand }
  }
}
</script>

<style lang="less" scoped>
.header-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #1a1a1a;

  .el-dropdown-link {
    display: flex;
    align-items: center;
    .user-name {
      margin-left: 10px;
      color: #999;
    }
  }

  .logo {
    padding-left: 47px;
    color: #fff;
    font-size: 32px;
    display: flex;
    align-items: center;

    & > img {
      height: 30px;
    }
  }

  .nav {
    flex: 1;
    height: 100%;
    color: #999;
  }

  .login {
    width: 250px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
  }
}
</style>
