/* eslint-disable space-before-function-paren */
import { login, logout, phoneLogin, getScreenAccountDetail } from '@/api/user'
import storage from 'store'
import { ACCESS_TOKEN, USER_INFO, SET_TOKEN, SET_USER_INFO } from '@/store/mutation-types'
import router from '@/router'

export default {
  state: () => ({
    token: '',
    userInfo: ''
  }),
  mutations: {
    [SET_TOKEN]: (state, payload) => {
      state.token = payload
    },
    [SET_USER_INFO]: (state, payload) => {
      state.userInfo = payload
    }
  },
  actions: {
    login({ commit, dispatch }, params) {
      const { account } = params
      const method = account ? login : phoneLogin
      const doLogin = async () => {
        const { data } = await method(params)
        console.log('%c [ data ]-26', 'font-size:13px; background:pink; color:#bf2c9f;', data)
        if (data) {
          storage.set(ACCESS_TOKEN, data.token, 7 * 24 * 60 * 60 * 1000)
          commit(SET_TOKEN, data.token)
          const { data: ruleData } = await getScreenAccountDetail(data.accountId)
          const ruleType = ruleData?.data?.type
          if (ruleType) {
            const userInfo = { ruleType, ...data }
            storage.set(USER_INFO, { ...data, ruleType }, 7 * 24 * 60 * 60 * 1000)
            commit(SET_USER_INFO, userInfo)
          } else {
            dispatch('logout')
            throw Error('您没有权限访问数据，请联系管理员！')
          }
          commit(SET_USER_INFO, data)
        } else {
          dispatch('logout')
          throw Error('密码不正确，请重新登录！')
        }
      }
      return doLogin()
    },

    logout({ commit }) {
      return new Promise(resolve => {
        logout()
          .then(() => {
            resolve()
          })
          .catch(() => {
            resolve()
          })
          .finally(() => {
            commit(SET_TOKEN, '')
            commit(SET_USER_INFO, '')
            storage.remove(ACCESS_TOKEN)
            storage.remove(USER_INFO)
            router.push('/login')
          })
      })
    }
  },
  getters: {
    userName(state) {
      return (state.userInfo && state.userInfo.accountName) || '用户名'
    },
    ruleType(state) {
      return state.userInfo?.ruleType
    }
  }
}
