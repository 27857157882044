export const ACCESS_TOKEN = 'Access_Token'
export const USER_INFO = 'User_Info'
export const ACCOUNT_PWD = 'User_account_password'

// user.js
export const SET_TOKEN = 'set_token'
export const SET_USER_INFO = 'set_user_info'

// industrialEconomy.js
export const SET_CURRENT_MENU_INDUSTRY = 'set_current_menu_industry'
export const SET_CURRENT_INDUSTRY = 'set_current_industry'

// buildingResources.js
export const SET_CURRENT_STREET_NAME = 'set_current_street_name'
export const SET_CURRENT_RENT_SALE_STATE = 'set_current_rent_sale_state'
export const SET_CURRENT_DATA_DIMENSIONAL = 'set_current_data_dimensional'
export const SET_CURRENT_BUILDING_TAG = 'set_current_building_tag'

// buildingTax.js
export const SET_STREET_PERCENT_DATA = 'set_street_percent_data'
export const SET_PROJECT_PERCENT_DATA = 'set_project_percent_data'
export const SET_CURRENT_YEAR_INDEX = 'set_current_year_index'
export const SET_YEAR_LIST = 'set_year_list'
// export const SET_CURRENT_INDUSTRY = 'set_current_industry'
export const SET_CURRENT_MENU_TAX = 'set_current_menu_tax'
export const SET_INTERVAL = 'set_interval'

// common.js
export const SET_STREET_LIST = 'set_street_list'
export const SET_STREET_PARAMS = 'set_street_params'
export const SET_BUILDING_PARAMS = 'set_building_params'
export const SET_BUILDING_LIST = 'set_building_list'
export const SET_COMMUNITY_LIST = 'set_community_list'
export const SET_COMMUNITY_LIST_PARAMS = 'set_community_list_params'
export const SET_HOVER_STREET_NAME = 'set_hover_street_name'
export const SET_HOVER_BUILDING_NAME = 'set_hover_building_name'
export const SET_MAP_BUILDING_NAME = 'set_map_building_name'
export const SET_MAP_STREET_NAME = 'set_map_street_name'
export const SET_COMPANY_LIST = 'set_company_list'
export const SET_CURRENT_STREET_ID = 'set_current_street_id'
export const SET_CURRENT_BUILDING_ID = 'set_current_building_id'
export const SET_CURRENT_STREET_DETAIL = 'set_current_street_detail'
export const SET_CURRENT_BUILDING_DETAIL = 'set_current_building_detail'
export const SET_INDUSTRY_LEVEL_0 = 'set_industry_level_0'
export const SET_MAP_LEVEL = 'set_map_level'
export const SET_HOVER_COMMUNITY_NAME = 'set_hover_community_name'
export const SET_MAP_COMMUNITY_NAME = 'set_map_community_name'
export const SET_MAP_STREET_GEOJSON = 'set_map_street_geojson'
export const SET_MAP_STREET_GEOJSON_PARAMS = 'set_map_street_geojson_params'
export const SET_MAP_COMMUNITY_GEOJSON = 'set_map_community_geojson'
export const SET_MAP_COMMUNITY_GEOJSON_PARAMS = 'set_map_community_geojson_params'
export const SET_MAP_BUILDING_GEOJSON = 'set_map_building_geojson'
export const SET_MAP_BUILDING_GEOJSON_PARAMS = 'set_map_building_geojson_params'
export const SET_CURRENT_COMMUNITY_ID = 'set_current_community_id'
export const SET_CURRENT_COMMUNITY_DETAIL = 'set_current_community_detail'
export const SET_CURRENT_YEAR = 'set_current_year'
export const SET_CURRENT_QUARTER = 'set_current_quarter'
export const SET_BUILDING_COMPANY_LIST = 'set_building_company_list'

// cloudmap

export const SET_CURRENT_COMPANY_KEY = 'set_current_company_key'
export const SET_CURRENT_INDUSTRY_CODE = 'set_current_industry_code'
export const SET_CURRENT_INDUSTRY_LABEL = 'set_current_industry_label'
export const SET_CURRENT_INDUSTRY_TAG = 'set_current_industry_tag'
