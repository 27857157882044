<template>
  <div class="custom-suspense-wrapper">
    <slot name="default" />

    <div class="custom-suspense-fallback" v-if="!isReady">
      <slot name="fallback">
        <Loading>loading...</Loading>
      </slot>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
export default {
  name: 'CustomSuspense',
  components: { Loading },
  props: {
    isReady: {
      type: Boolean,
      required: true
    }
  },
  setup: () => {
    return {}
  }
}
</script>

<style lang="less" scoped>
.custom-suspense-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .custom-suspense-fallback {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: #f00;
    z-index: 1000;
  }
}
</style>
