import { scaleQuantize } from 'd3-scale'

export const debounce = (fn, wait = 300, immediate = false) => {
  let timer
  return (...args) => {
    if (timer) clearTimeout(timer)

    if (immediate) {
      // 防止函数报错后，防抖失效
      const callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)

      if (callNow) fn(...args)
    } else {
      timer = setTimeout(() => {
        fn(...args)
      }, wait)
    }
  }
}

export const randomNum = (minNum, maxNum) => {
  return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
}

export const calculateCenter = lnglatarr => {
  const total = lnglatarr.length
  let X = 0
  let Y = 0
  let Z = 0

  lnglatarr.forEach(lnglat => {
    const lng = (lnglat[0] * Math.PI) / 180
    const lat = (lnglat[1] * Math.PI) / 180
    const x = Math.cos(lat) * Math.cos(lng)
    const y = Math.cos(lat) * Math.sin(lng)
    const z = Math.sin(lat)
    X += x
    Y += y
    Z += z
  })

  X = X / total
  Y = Y / total
  Z = Z / total

  var Lng = Math.atan2(Y, X)
  var Hyp = Math.sqrt(X * X + Y * Y)
  var Lat = Math.atan2(Z, Hyp)

  return [(Lng * 180) / Math.PI, (Lat * 180) / Math.PI]
}

export const wgs84ToGaode = async geoJson => {
  const result = {
    type: 'FeatureCollection',
    features: []
  }
  const { features } = geoJson
  const resultPromise = features.map(async f => {
    const { coordinates, type } = f.geometry

    let c = [...coordinates]
    if (type === 'Polygon') {
      c = c[0]
    } else if (type === 'Point') {
      c = [c]
    }
    const a = new Promise(resolve => {
      window.AMap.convertFrom(c, 'gps', (status, result) => {
        if (result.info === 'ok') {
          const coords = result.locations
          // console.log('🚀 ~ file: util.js ~ line 73 ~ window.AMap.convertFrom ~ coords', coords)

          if (type === 'Polygon') {
            resolve([coords.map(d => [d.lng, d.lat])])
          } else if (type === 'Point') {
            resolve(coords.map(d => [d.lng, d.lat])[0])
          } else {
            resolve(coords.map(d => [d.lng, d.lat]))
          }
        }
      })
    })

    const b = await a

    // console.log(b)
    return {
      type: 'Feature',
      properties: f.properties,
      geometry: {
        coordinates: b,
        type: f.geometry.type
      }
    }
  })

  result.features = await Promise.all(resultPromise)
  console.log(JSON.stringify(result))
  return result
}

/**
 * 格式化税收数据
 * @param {Number} value
 * @returns
 */
export const formatTax = value => {
  if (!value) return 0
  return Number((value / 10000).toFixed(2))
}
/**
 * 格式化注册资金数据
 * @param {Number} value
 * @returns
 */
export const formatCapital = value => {
  if (!value) return 0
  if (isNaN(value)) return 0
  return Number(Number(value).toFixed(2))
}

/**
 * 格式化税收数据
 * @param {Number} value
 * @returns
 */
const scale = scaleQuantize()
  .domain([0, 10000])
  .range(['E类', 'D类', 'C类', 'B类', 'A类'])
export const formatCompanyTax = value => {
  if (!value) {
    return {
      scale,
      value: scale(0)
    }
  }

  return {
    scale,
    value: scale(Number((value / 10000).toFixed(2)))
  }
}

export const sixIndustryNames = [
  '总部经济',
  '数字经济',
  '现代金融',
  '商贸商务',
  '航运贸易',
  '文化旅游',
  '其他产业'
]
