import http from '@/utils/http'
import { getBaseUrl } from '@/config/apiConfig'
import dbMap from './dbMap'
const BASE_URL = getBaseUrl().BASE_URL
const BASE_URL2 = getBaseUrl().BASE_URL2

const api = {
  findList: `${BASE_URL}/commonInstance/findList`,
  findListAll: `${BASE_URL}/commonInstance/findListAll`,
  findListNoLimit: `${BASE_URL}/commonInstance/findListNoLimit`,
  detail: `${BASE_URL}/commonInstance/detail`,
  industry: `${BASE_URL}/sys/industry/list`,
  dictionaryDetail: `${BASE_URL}/dictionary/find`, // 字典详情
  communityList: '/communityList.json',
  findList2: `${BASE_URL2}/commonInstance/findList`
}
export const getPolicyDocumentPageList = (params = {}) => {
  const {
    conditions = {},
    fields = {},
    sort = {},
    // eslint-disable-next-line no-unused-vars
    pageInfo = {
      pageNum: 1,
      pageSize: 50000
    },
    templateName = 'b_console_policy_document'
  } = params

  return http({
    url: api.findList,
    method: 'post',
    data: {
      conditions,
      fields,
      sort,
      pageInfo,
      templateName
    }
  })
}
export const getRoomListByBuildingId = id => {
  const params = {
    conditions: {
      'data.registerBuilding': `NumberLong(${id})`
    },
    fields: {},
    pageInfo: {
      pageNum: 1,
      pageSize: 20
    },
    sort: {},
    templateName: dbMap.RENTING_INFORMATION
  }

  return http({
    url: api.findListAll,
    method: 'post',
    data: params
  })
}

export const getStreetList = (params = {}) => {
  const {
    conditions = {},
    // fields = { data: { name: true } },
    fields = {},
    sort = {}, // {key: 1 | -1}  1为升序 -1为降序
    templateName = dbMap.STREET_INFORMATION
  } = params

  return http({
    url: api.findListAll,
    method: 'post',
    data: {
      conditions,
      fields,
      sort,
      templateName
    }
  })
}

export const getBuildingList = (params = {}) => {
  const {
    conditions = {},
    fields = { data: { name: true, crd: true, street: true, leaveArea: true } },
    sort = {}, // {key: 1 | -1}  1为升序 -1为降序
    pageInfo,
    templateName = dbMap.BUILDING_INFORMATION
  } = params

  return http({
    url: api.findListAll,
    method: 'post',
    data: {
      conditions,
      fields,
      sort,
      pageInfo,
      templateName
    }
  })
}

export const getCompanyList = (params = {}) => {
  const {
    conditions = { stats: { $exists: true } },
    fields = { data: { regCrd: true } },
    sort = {},
    // eslint-disable-next-line no-unused-vars
    pageInfo = {
      pageNum: 1,
      pageSize: 50000
    },
    templateName = dbMap.ENTERPRISE_INFORMATION
  } = params

  return http({
    url: api.findListAll,
    method: 'post',
    data: {
      conditions,
      fields,
      sort,
      // pageInfo,
      templateName
    }
  })
}

export const getCompanyPageList = (params = {}) => {
  const {
    conditions = { stats: { $exists: true } },
    fields = { data: { regCrd: true } },
    sort = {},
    // eslint-disable-next-line no-unused-vars
    pageInfo = {
      pageNum: 1,
      pageSize: 10
    },
    templateName = dbMap.ENTERPRISE_INFORMATION
  } = params

  return http({
    url: api.findList,
    method: 'post',
    data: {
      conditions,
      fields,
      sort,
      pageInfo,
      templateName
    }
  })
}

export const getCompanyNoLimit = (params = {}) => {
  const {
    conditions = { 'data.regCrd': { $exists: 1 } },
    fields = {
      data: { regCrd: 1, regCapitalValue: 1 },
      stats: 1
    },
    sort = {},
    // eslint-disable-next-line no-unused-vars
    pageInfo = {
      pageNum: 1,
      pageSize: 5000
    },
    templateName = dbMap.ENTERPRISE_INFORMATION
  } = params

  return http({
    url: api.findListNoLimit,
    method: 'post',
    data: {
      conditions,
      fields,
      sort,
      // pageInfo,
      templateName
    }
  })
}

export const getStreetDetail = id => {
  return http({
    url: `${api.detail}/street_information/${id}`,
    method: 'get'
  })
}

export const getBuildingDetail = id => {
  return http({
    url: `${api.detail}/building_information/${id}`,
    method: 'get'
  })
}

export const getCompanyDetail = id => {
  return http({
    url: `${api.detail}/enterprise_information/${id}`,
    method: 'get'
  })
}

export const getCommunityDetail = id => {
  return http({
    url: `${api.detail}/community_information/${id}`,
    method: 'get'
  })
}

export const getIndustryListLevel0 = () => {
  return http({
    url: api.industry,
    method: 'get',
    params: { parentId: 0 }
  })
}

/**
 * 获取字典详情
 **/
export const getDictionaryDetail = key => {
  return http({
    url: `${api.dictionaryDetail}/${key}`,
    method: 'get'
  })
}

/**
 *
 * @returns 社区列表
 */
export const getCommunityList = () => {
  return http({
    url: api.communityList,
    method: 'get'
  })
}

export const getCommunityPageList = (params = {}) => {
  const {
    conditions = {},
    fields = {},
    sort = {}, // {key: 1 | -1}  1为升序 -1为降序
    templateName = dbMap.B_CONSOLE_COMMUNITY
  } = params

  return http({
    url: api.findListNoLimit,
    method: 'post',
    data: {
      conditions,
      fields,
      sort,
      templateName
    }
  })
}

export const getDetailCompanyList = params => {
  return http({
    url: api.findList,
    method: 'post',
    data: params
  })
}

export const getPolicyProjectApply = (params = {}) => {
  const {
    conditions = {},
    sort = {}, // {key: 1 | -1}  1为升序 -1为降序
    pageInfo = {
      pageNum: 1,
      pageSize: 60
    },
    templateName = dbMap.B_CONSOLE_POLICY_PROJECT_APPLY
  } = params

  return http({
    url: api.findList2,
    method: 'post',
    data: {
      conditions,
      sort,
      pageInfo,
      templateName
    }
  })
}
