import {
  ElRadio,
  ElRadioGroup,
  ElTabs,
  ElTabPane,
  ElImage,
  ElTable,
  ElTableColumn,
  ElRow,
  ElCol,
  ElPagination,
  ElIcon,
  ElSlider,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElSpace,
  ElForm,
  ElFormItem,
  ElInput,
  ElCheckbox,
  ElButton,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElInfiniteScroll,
  ElDrawer
} from 'element-plus'
export default {
  install: app => {
    app.use(ElRadio)
    app.use(ElRadioGroup)
    app.use(ElTabs)
    app.use(ElTabPane)
    app.use(ElImage)
    app.use(ElRow)
    app.use(ElCol)
    app.use(ElTable)
    app.use(ElTableColumn)
    app.use(ElPagination)
    app.use(ElIcon)
    app.use(ElSlider)
    app.use(ElSelect)
    app.use(ElOption)
    app.use(ElDatePicker)
    app.use(ElSpace)
    app.use(ElForm)
    app.use(ElFormItem)
    app.use(ElInput)
    app.use(ElCheckbox)
    app.use(ElButton)
    app.use(ElDropdown)
    app.use(ElDropdownItem)
    app.use(ElDropdownMenu)
    app.use(ElInfiniteScroll)
    app.use(ElDrawer)
  }
}
