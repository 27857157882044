import { useStore } from 'vuex'
import { onBeforeUnmount } from 'vue'

export default () => {
  const store = useStore()
  const showLoading = () => {
    store.commit('setReadyState', false)
  }

  const hidLoading = t => {
    if (t) {
      setTimeout(() => {
        store.commit('setReadyState', true)
      }, t)
    } else {
      store.commit('setReadyState', true)
    }
  }

  onBeforeUnmount(() => {
    showLoading()
  })

  return { showLoading, hidLoading }
}
