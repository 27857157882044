<template>
  <div class="icon-svg-wrapper" :style="style">
    <svg class="icon" aria-hidden="true">
      <use :xlink:href="iconName"></use>
    </svg>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'IconSvg',
  props: {
    name: String,
    prefix: {
      type: String,
      default: 'icon'
    },
    style: Object
  },
  setup: props => {
    const iconName = computed(() => `#${props.prefix}-${props.name}`)
    return { iconName }
  }
}
</script>

<style lang="less" scoped>
.icon-svg-wrapper {
  display: inline-block;

  .icon {
    width: 100%;
    height: 100%;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
}
</style>
