<template>
  <div class="blank-layout-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'BlankLayout',
  setup: () => {
    return {}
  }
}
</script>

<style lang="less" scoped>
.blank-layout-wrapper {
  height: 100%;
}
</style>
